<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="45%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.salon_id }">
							<label>Salón</label>
							<el-select v-model="form.salon_id" dusk="salon_id">
								<el-option
									v-for="option in salons"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.salon_id"
								v-text="errors.salon_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.name }">
							<label>Nombre</label>
							<el-input v-model="form.name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.capacity }">
							<label>Capacidad</label>
							<el-input-number
								v-model="form.capacity"
								:min="1"
								:max="20"
								controls-position="right"
							></el-input-number>
							<small
								class="error--text"
								v-if="errors.capacity"
								v-text="errors.capacity[0]"
							></small>
						</div>
					</v-col>
				</v-row>

				<div class="form-actions text-right mt-4 mb-4">
					
					<el-checkbox v-model="continueCreate" class="mr-5">Seguir creando</el-checkbox>
					
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</div>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'tables',
			valid: false,
			salons: [],
			errors: {},
			form: {},
			continueCreate: true
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				salon_id: null,
				name: null,
				capacity: 1,
			};
		},
		changeScene() {
			console.log(this.form.salon_id);
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar mesa' : 'Nueva mesa';
			await this.initForm();
			await this.getTables();

			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form = response.data.data;
				});
			}
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.salons = response.data.salons;

				this.form.salon_id = this.salons.length > 0 ? this.salons[0].id : null;
			});
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						// this.close();
						this.initForm();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
